<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import type { ManageAPI } from "~/manage-api/types";
  import Carousel, {
    type CarrouselConfig,
  } from "../carousels/FiveItemCarousel.svelte";
  import OfferCard from "./OfferCard.svelte";
  import Title from "../common/Title.svelte";
  import FiveItemCardLink from "../carousels/FiveItemCardLink.svelte";
  import { getOffersByStream } from "./utils";
  import { imageRatioValues } from "../../configuration";
  import { getOneOfferPerStore } from "~/util/offer";
  import type { CarouselInfo } from "../carousels/utils";

  let class_list = "";
  export { class_list as class };
  export let request_store_key: StoresKey;
  export let carouselInfo: CarouselInfo;
  export let offerStreamID = 3;

  const stores_context = getStoresContext(request_store_key);
  const { offers, store } = stores_context;
  const {
    titleTextColor,
    textColor,
    textboxBackgroundColor,
    linkTextbox,
    linkTitle,
  } = carouselInfo;
  
  let filtered_offers: ManageAPI.Offer[] = [];
  let config: CarrouselConfig = {};

  $: if ($offers && offerStreamID) {
    filtered_offers = getOneOfferPerStore([...$offers]);
    filtered_offers = getOffersByStream(
      filtered_offers,
      offerStreamID,
      offerStreamID == 1,
    );
    config = {
      aspectRatio: imageRatioValues["OFFER"],
      count: filtered_offers.length + 1,
      initalSlideTo: filtered_offers.length - 3 || 0,
      pos: filtered_offers.length >= 2 ? filtered_offers.length - 2 : 0,
    };
  }

  // For store's own page:
  $: if ($offers && $store) {
    filtered_offers = [...$offers].filter(offer => offer.store?.id === $store.id);
    config = {
      aspectRatio: imageRatioValues["OFFER"],
      count: filtered_offers.length + 1,
      initalSlideTo: filtered_offers.length - 3 || 0,
      pos: filtered_offers.length >= 2 ? filtered_offers.length - 2 : 0,
    };
  }
  
</script>

{#if filtered_offers.length}
  <div class="py-6 {class_list}">
      {#if linkTitle}
        <Title {linkTitle} {titleTextColor} />
      {/if}
      <Carousel {config}>
        <svelte:fragment slot="swiper">
          {#each filtered_offers as offer, index}
            <swiper-slide>
              <OfferCard
                {offer}
                {request_store_key}
                aspectRatio={config.aspectRatio} />
            </swiper-slide>
            {#if index == config.pos && linkTextbox}
              <swiper-slide>
                <FiveItemCardLink
                  {textColor}
                  backgroundColor={textboxBackgroundColor}
                  {linkTextbox}
                  aspectRatio={config.aspectRatio} />
              </swiper-slide>
            {/if}
          {/each}
        </svelte:fragment>
      </Carousel>
  </div>
{/if}
